import { Link } from "gatsby"
import React, { Fragment } from "react"
import Seo from "../components/seo"

const browser = typeof window !== "undefined" && window;

const NotFoundTemplate = () => {
  return (
    browser && (
      <Fragment>
      <Seo title="404: Not found" />
      <div className="container not-found">
        <h1>404: Not Found</h1>
        <p>Looks like the page you are looking for doesn't exist.</p>
        <Link to="/" className="btn btn-warning">
          Back to Home
        </Link>
      </div>
    </Fragment>
    )
  )
}



export default NotFoundTemplate
